import React from 'react'

export default function howtoenrolll({ lang, howtoenroll}) {
  return (

    <div id={howtoenroll.anchor} className='m-h-t-e nav-section'>
        <div className='section-divider-bg'></div>
        <div className='inner'>
            <h2 dangerouslySetInnerHTML={{__html: howtoenroll.hA[lang]}}/>
            <div className='col'>
                <img src={howtoenroll.imgA.src} className='icon' alt={howtoenroll.imgA[lang]}/>
                <h3>{howtoenroll.hB[lang]}</h3>
                <p dangerouslySetInnerHTML={{__html: howtoenroll.pA[lang]}}/>
            </div>
            <div className='col'>
                <img src={howtoenroll.imgB.src} className='icon' alt={howtoenroll.imgB[lang]}/>
                <h3>{howtoenroll.hC[lang]}</h3>
                <p dangerouslySetInnerHTML={{__html: howtoenroll.pB[lang]}}/>
            </div>
        </div>
    </div>
  )
}
