import React from 'react'

export default function WhatIs({ lang, whatis }) {

  const list = whatis.listA

  return (
    <div id={whatis.anchor} className='m-w-i nav-section'>
        <div className='section-divider-bg'></div>
        <div className='inner'>
            <div className='title-icon'>
                <img src={whatis.imgA.src} className='icon' alt={whatis.imgA[lang]}/>
                <h2 dangerouslySetInnerHTML={{__html: whatis.hA[lang]}}/>
            </div>
            <p dangerouslySetInnerHTML={{__html: whatis.pA[lang]}}/>
            <p className='sub-head'>{whatis.pB[lang]}</p>
            <img src={whatis.imgB.srcMulti[lang]} id='symptoms' alt={whatis.imgB[lang]}/>
            <div id='symptoms-list' className='col'>
              {
                list.map((listItem, index)=>(
                  <span 
                  key={index}
                  className='row'>
                    <img 
                    key={index + 'img'}
                    src={listItem.img.src}
                    alt={listItem.img[lang]}/>
                    {listItem[lang]}
                  </span>
                ))
              } 
            </div>
            <p dangerouslySetInnerHTML={{__html: whatis.pC[lang]}}/>
            <p dangerouslySetInnerHTML={{__html: whatis.pD[lang]}}/>
            <p dangerouslySetInnerHTML={{__html: whatis.pE[lang]}}/>
        </div>
    </div>
  )
}
