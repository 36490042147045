import React from 'react'

export default function Quiz({lang, quiz}) {
  const count = quiz.questions.length,
        lastIndex = count - 1,
        quizAnswers = quiz.answers,
        updateTabIndexes = () => {
          const $quiz = document.getElementById('quiz'),
                questions = $quiz.querySelectorAll('.question')

          for(let i = 0, len = questions.length; i < len; i++){
            const question = questions[i],
                  isActive = question.className === 'question',
                  tabIndex = isActive ? 0 : -1,
                  indexables = question.querySelectorAll('button')
            
            for(let j = 0, jLen = indexables.length; j < jLen; j++){
              indexables[j].tabIndex = tabIndex
            }
          }
        },
        answer = (e, index, value) => {
          e.preventDefault()

          const card = e.target.offsetParent,
                row = card.querySelector('.row'),
                buttons = (()=>{
                  const cols = row.querySelectorAll('.col')
                  return [cols[0].querySelector('button'), cols[1].querySelector('button')]
                })(),
                nextBtn = document.getElementById('quiz-next-' + index),
                score = document.getElementById('quiz-score-' + index)

          value ? (buttons[0].className = 'radio active') && (buttons[1].className = 'radio')
          : (buttons[0].className = 'radio') && (buttons[1].className = 'radio active')
          
          //  question pass / fail
          if(quizAnswers[index] === value)
          {
            //lastIndex
            if(lastIndex === index) {
              nextBtn.className = 'none'
              score.className = 'text-color-primary bold active'
              score.innerHTML = quiz.pass[lang]
            }
            else{
              nextBtn.className = 'next'
              score.className = 'none'
              score.classList.remove('active')
            }
          }
          else{
            nextBtn.className = 'none'
            score.className = 'text-color-secondary bold active'
            score.innerHTML = quiz.fail[lang]
          }
        },
        previous = () =>{
          const cards = document.querySelectorAll(`.question`),
                //  clean card index - if the user clicks the anchor link
                //  after completing the study it resets our js
                cleanCardIndex = cardIndex !== 0 ? cardIndex : count - 1,
                prevIndex = cleanCardIndex - 1

          cards[cleanCardIndex].className = 'question not-asked'
          cards[prevIndex].className = 'question'

          cardIndex = prevIndex

          updateTabIndexes()
        },
        next = () => {

          const cards = document.querySelectorAll(`.question`),
                nextIndex = cardIndex + 1

          cards[cardIndex].className = 'question answered'
          cards[nextIndex].className = 'question'

          cardIndex = nextIndex

          updateTabIndexes()
        }
  
  let cardIndex = 0

  return (
    <div id='quiz'>
      {
        quiz.questions.map((question, index)=>(
          <div 
          key={`${index}-question`}
          className={index > 0 ? 'question not-asked' : 'question'}>
            {
              index > 0 ? 
              <div className='h3-wrapper' key={`${index}-aa`}>
                <button
                tabIndex='-1'
                key={`${index}-a`} 
                aria-label='back' 
                onClick={()=>previous()}
                className='back no-focus-style'/>
                <h3 key={`${index}-b`}>{`${quiz.question[lang]} ${index + 1} ${quiz.questionArticle[lang]} ${count}`}</h3>
              </div>
              :
              <div className='h3-wrapper'>
                <h3 key={`${index}-b`}>{`${quiz.question[lang]} 1 ${quiz.questionArticle[lang]} ${count}`}</h3>
              </div>
            }
            <p key={`${index}-d`}
            dangerouslySetInnerHTML={{__html: question[lang]}}/>
            <div key={`${index}-e`} className='row'>
              <div key={`${index}-f`} className='col'>
                <button
                tabIndex={index === 0 ? 0 : -1}
                aria-label='yes'
                key={`${index}-g`}
                className="radio"
                onClick={(e)=>{
                  window.dataLayer && window.dataLayer.push({'event': 'quiz-button-yes', 'index': index + 1})
                  answer(e, index, true)
                  }}><span></span></button>
                <p key={`${index}-h`}>{quiz.positive[lang]}</p>
              </div>
              <div key={`${index}-i`} className='col'>
                <button
                tabIndex={index === 0 ? 0 : -1}
                aria-label='no'
                key={`${index}-j`}
                className="radio"
                onClick={(e)=>{
                  window.dataLayer && window.dataLayer.push({'event': 'quiz-button-no', 'index': index + 1})
                  answer(e, index, false)
                  }}><span></span></button>
                <p key={`${index}-k`}>{quiz.negative[lang]}</p>
              </div>
            </div>
            <button
            tabIndex={index === 0 ? 0 : -1}
            id={'quiz-next-' + index}
            className={ index === lastIndex ? 'opacity-0' : 'next disabled'}
            key={`${index}-l`}
            type='button'
            aria-label='next'
            data-index={index}
            onClick={()=>next()}>{quiz.next[lang]}</button>
            
            <p 
            key={`${index}-m`} 
            className='quiz-score'
            id={'quiz-score-' + index}></p>
          </div>
        ))
      }
    </div>
  )
}
