import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

export default function Hero({ lang, hero }) {
    
    const   linkTo = lang === 'es' ?  '/' : 'index-es',
            linkToLabel = lang === 'es' ? 'English' : 'Español',
            languageClassName = hero.showLanguage  === 't' ? 'inner language' : 'none'

    return (
        <div id='hero'>
            <div className={languageClassName}><Link to={linkTo}>{linkToLabel}<span></span></Link></div>
            <div id='floating-molecule'>
                <StaticImage
                src="../../raster/molecule.png"
                alt="Red blood cell background graphic"
                placeholder="none"
                />
            </div>
            <div className='inner'>
                <h1 dangerouslySetInnerHTML={{__html: hero.hA[lang]}}/>
                <p dangerouslySetInnerHTML={{__html: hero.pA[lang]}}/>
                <button className='internal_link' id='eligible' type='button' data-section='who-can-take-part'>{hero.btnA[lang]}</button>  
            </div>
        </div>
    )
}