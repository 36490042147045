import React from 'react'
import LocationsAccordian from './LocationsAccordian'

export default function Locations({ lang, locations, locationdata, sitecore }) {
    return (
        <div id={locations.anchor} className='m-l nav-section'>
            <div className='section-divider-white'></div>
            <div className='inner'>
                <div className='title-icon'>
                    <img src={locations.imgA.src} className='icon' alt={locations.imgA[lang]}/>
                    <h2>{locations.hA[lang]}</h2>
                </div>
                <p
                dangerouslySetInnerHTML={{__html: locations.pA[lang]}}
                />
                <LocationsAccordian
                    lang={lang}
                    locationdata={locationdata}
                    sitecore={sitecore}
                    />
            </div>
        </div>
    )
}
