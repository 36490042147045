import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function StudyDetails({ lang, studydetails }) {

    const
    list = (()=>{
        const l = studydetails.listA,
                a = []
        for(let i = 0, len = l.length; i < len; i++){
            a.push(l[i][lang])
        }
        return a
    })()

    return (
        <div id={studydetails.anchor} className='m-s-d nav-section'>
            <div className='section-divider-bg'></div>
            <div id='dna-strand'>
                <StaticImage
                src="../../raster/dna-strand.png"
                alt="DNA strand background graphic"
                placeholder="none"
                />
            </div>

            <div className='inner'>

                <img className='logo' src={studydetails.imgA.src} alt={studydetails.imgA[lang]}/>
                <div className='title-icon'>
                    <img src={studydetails.imgB.src} className='icon' alt={studydetails.imgB[lang]}/>
                    <h2>{studydetails.hA[lang]}</h2>
                </div>
                <p className='sub-head' dangerouslySetInnerHTML={{__html: studydetails.pA[lang]}}/>
                <p className='pill-wrapper' dangerouslySetInnerHTML={{__html: studydetails.pB[lang]}}/>


                <div className='mobile-only'>
                    <div className='col'>
                        <div className='row'>
                            <img src={studydetails.imgC.src} alt={studydetails.imgC[lang]}/>
                            <p
                            className='bold'
                            dangerouslySetInnerHTML={{__html: studydetails.pC[lang]}}
                            />
                        </div>
                        
                        <div className='bullet-wrapper'>
                            <ul>
                                {
                                    list.map((item, index)=>(
                                        <li 
                                        key={index}
                                        dangerouslySetInnerHTML={{__html: item}}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='tablet-and-desktop-only'>
                    <div className='row'>
                        <img src={studydetails.imgC.src} alt={studydetails.imgC[lang]}/>
                        <div className='col left'>
                        <p
                            className='bold'
                            dangerouslySetInnerHTML={{__html: studydetails.pC[lang]}}
                        />

                            <ul>
                                {
                                    list.map((item, index)=>(
                                        <li 
                                        key={index}
                                        dangerouslySetInnerHTML={{__html: item}}
                                        />
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <p>{studydetails.pD[lang]}</p>
            </div>
        </div>
    )
}
