import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function WhatToExpect({ lang, whattoexpect }) {

    const
    phases = (()=>{
        const   l = whattoexpect.listA,
                a = []
        for(let i = 0, len = l.length; i < len; i++){
            const   listItem = l[i],
                    item = {
                        duration: listItem.pA[lang],
                        title: listItem.hA[lang],
                        list: (()=>{
                            const   ll = listItem.list,
                                    aa = []
                            
                            for(let j = 0, jLen = ll.length; j < jLen; j++){
                                aa.push(ll[j][lang])
                            }

                            return aa
                        })()
                    }
                    
            a.push(item)
        }
        return a
    })()

    return (
        <div id={whattoexpect.anchor} className='m-w-t-e nav-section'>
            <div className='section-divider-bg'></div>
            <div className='desktop-only'>
                <div id='test-tubes'>
                    <StaticImage
                    src="../../raster/test-tubes.png"
                    alt="Test tubes background graphic"
                    placeholder="none"
                    />
                </div>
            </div>
            <div className='inner'>
                <div className='title-icon'>
                    <img src={whattoexpect.imgA.src} className='icon' alt={whattoexpect.imgA[lang]}/>
                    <h2 dangerouslySetInnerHTML={{__html: whattoexpect.hA[lang]}}/>
                </div>
                <p>{whattoexpect.pA[lang]}</p>
                
                <div className='col phases'>
                    {
                        phases.map((item, index)=>(
                            <React.Fragment key={index + 'frag'}>
                                <div key={index} className='col phase'>
                                    <p
                                        className='phase-duration'
                                        key={index + 'p'}>{item.duration}</p>
                                    <h3
                                        key={index + 'h3'}>{item.title}</h3>
                                    <ul>
                                        {item.list.map((listItem, listIndex) => (
                                            <li key={listIndex} dangerouslySetInnerHTML={{ __html: listItem }} />
                                        ))}
                                    </ul>
                                </div>
                                
                                {
                                    
                                    index !== phases.length - 1 && <img src={whattoexpect.imgB.src} key={index + 'img'} className='arrow' alt={whattoexpect.imgB[lang]} />
                                }

                                
                            </React.Fragment>
                        ))
                    }
                </div>	
                <div className='annotation'>
                    <div className='col subtext'> 
                        <div className='row'>
                            <p>*</p>   
                            <p>{whattoexpect.pB[lang]}</p>
                        </div>
                    </div>
                </div>	
                <div className='row'>
                    <p className='text-color-white aligner'>*</p>
                    <p dangerouslySetInnerHTML={{__html: whattoexpect.pC[lang]}}/>
                </div>
            </div>
        </div>
    )
}
  
