import React from 'react'
import Quiz from './Quiz'

export default function WhoCanTakePart({ lang, whocantakepart, quiz }) {

    return (
        <div id={whocantakepart.anchor} className='m-w-c-t-p nav-section'>
            <div className='section-divider-white'></div>
            <div className='inner'>
                <div className='title-icon'>
                    <img src={whocantakepart.imgA.src} className='icon' alt={whocantakepart.imgA[lang]}/>
                    <h2>{whocantakepart.hA[lang]}</h2>
                </div>
                <Quiz lang={lang} quiz={quiz}></Quiz>
                <img className='logo' src={whocantakepart.imgB.src} alt={whocantakepart.imgB[lang]}/>
                <div id='important-details'>
                    <div className='col'>
                        <img className='icon' src={whocantakepart.imgC.src} alt={whocantakepart.imgC[lang]}/>
                        <p dangerouslySetInnerHTML={{__html: whocantakepart.pA[lang]}}/>
                    </div>
                    <div className='col'>
                        <img className='icon' src={whocantakepart.imgD.src} alt={whocantakepart.imgD[lang]}/>
                        <p dangerouslySetInnerHTML={{__html: whocantakepart.pB[lang]}}/>
                    </div>
                    <div className='col'>
                        <img className='icon' src={whocantakepart.imgE.src} alt={whocantakepart.imgE[lang]}/>
                        <p dangerouslySetInnerHTML={{__html: whocantakepart.pC[lang]}}/>
                    </div>
                </div>
                <div className='annotation'>
                    <p dangerouslySetInnerHTML={{__html: whocantakepart.pD[lang]}}/>
                </div>
            </div>
        </div>
    )
}
