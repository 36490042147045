import React from 'react'
import { useEffect } from 'react'
import SiteCore from '../scripts/SiteCore'
import '../components/styles/global.scss'
import { Seo } from '../components/common/Seo'
import Header from '../components/common/Header'
import Hero from '../components/common/Hero'
import WhatIs from '../components/common/WhatIs'
import WhyTakePart from '../components/common/WhyTakePart'
import StudyDetails from '../components/common/StudyDetails'
import WhoCanTakePart from '../components/common/WhoCanTakePart'
import WhatToExpect from '../components/common/WhatToExpect'
import Locations from '../components/common/Locations'
import HowToEnroll from '../components/common/HowToEnroll'
import Contact from '../components/common/Contact'
import Footer from '../components/common/Footer'
import CookieBanner from '../components/common/CookieBanner'
import { graphql } from 'gatsby'

export default function Home({ data }) {
  
  // test

  const definitions = data.definitions.nodes[0].definitions,
        header = data.header.nodes[0],
        cookies = data.cookies.nodes[0],
        hero = data.hero.nodes[0],
        whatis = data.whatis.nodes[0],
        whytakepart = data.whytakepart.nodes[0],
        studydetails = data.studydetails.nodes[0],
        quiz = data.quiz.nodes[0],
        whocantakepart = data.whocantakepart.nodes[0],
        whattoexpect = data.whattoexpect.nodes[0],
        locations = data.locations.nodes[0],
        locationdata = data.locationdata.nodes[0],
        howtoenroll = data.howtoenroll.nodes[0],
        contact = data.contact.nodes[0],
        footer = data.footer.nodes[0],
        sitecore = new SiteCore()

  useEffect(() => {
    
    sitecore.init(definitions, 'en')
    //console.log(process.env.GTM_ID_KEY)
    //  run an extra scroll update
    //  resolves bug where when reloading page the sticky class wasnt applied 
    //  to the nav bar
    sitecore.scrollUpdate()
  }, [definitions])

  return (
    <div id='microsite'>
      <Header lang='en' header={header}/>
      <div id='content-wrapper'>
        <Hero lang='en' hero={hero}/>
        <WhatIs lang='en' whatis={whatis}/>
        <WhyTakePart lang='en' whytakepart={whytakepart}/>
        <StudyDetails lang='en' studydetails={studydetails}/>
        <WhoCanTakePart lang='en' whocantakepart={whocantakepart} quiz={quiz}/>
        <WhatToExpect lang='en' whattoexpect={whattoexpect}/>
        <Locations
          lang='en'
          locations={locations}
          locationdata={locationdata}
          sitecore={sitecore}
        />
        <HowToEnroll lang='en' howtoenroll={howtoenroll}/>
        <Contact lang='en' contact={contact}/>
        <Footer lang='en' footer={footer}/>
        <CookieBanner lang='en' cookies={cookies}/>
      </div>
    </div>
  )
}

export const Head = () => (
  <Seo />
)

export const query = graphql`
query SiteEnglish {
  definitions: allDataYaml(filter: {yamlId: {eq: "definitions"}}) {
    nodes {
      definitions {
        id
        title {
          en
        }
        definition {
          en
        }
      }
    }
  }
  cookies: allDataYaml(filter: {yamlId: {eq: "cookies"}}) {
    nodes {
      consent {
        en
      }
      accept {
        en
      }
      decline {
        en
      }
    }
  }
  header: allDataYaml(filter: {yamlId: {eq: "header"}}) {
    nodes {
      showFHP
      imgA {
        en
        src
      }
      linkA {
        en
        url
      }
      buttons {
        en
        anchor
      }
    }
  }
  hero: allDataYaml(filter: {yamlId: {eq: "hero"}}) {
    nodes {
      showLanguage
      btnA {
        en
      }
      hA {
        en
      }
      pA {
        en
      }
    }
  }
  whatis: allDataYaml(filter: {yamlId: {eq: "whatis"}}) {
    nodes {
      anchor
      hA {
        en
      }
      pB {
        en
      }
      pA {
        en
      }
      imgA {
        en
        src 
      }
      imgB {
        en
        srcMulti {
          en
        }
      }
      listA {
        en
        img {
          en
          src
        }
      }
      pC {
        en
      }
      pD {
        en
      }
      pE {
        en
      }
    }
  }
  whytakepart: allDataYaml(filter: {yamlId: {eq: "whytakepart"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      listA {
        en
        img {
          en
          src
        }
      }
      pA {
        en
      }
    }
  }
  studydetails: allDataYaml(filter: {yamlId: {eq: "studydetails"}}) {	
    nodes {
      anchor
      imgA {
        en
        src
      }
      imgB {
        en
        src
      }
      imgC {
        en
        src
      }
      hA {
        en
      }
      listA {
        en
      }
      pA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      pD {
        en
      }
    }
  }
  quiz: allDataYaml(filter: {yamlId: {eq: "quiz"}}) {
    nodes{
      question {
        en
      }
      questionArticle {
        en
      }
      positive {
        en
      }
      negative {
        en
      }
      next {
        en
      }
      questions{
        en
      }
      answers
      pass{
        en
      }
      fail{
        en
      }
    }
  }
  whocantakepart: allDataYaml(filter: {yamlId: {eq: "whocantakepart"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      imgB {
        en
        src
      }
      imgC {
        en
        src
      }
      imgD {
        en
        src
      }
      imgE {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      pD {
        en
      }
    }
  }
  whattoexpect: allDataYaml(filter: {yamlId: {eq: "whattoexpect"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      imgB {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
      pB {
        en
      }
      pC {
        en
      }
      listA {
        hA {
          en
        }
        list {
          en
        }
        pA {
          en
        }
      }
    }
  }
  locations: allDataYaml(filter: {yamlId: {eq: "locations"}}) {
    nodes {
      anchor
      imgA {
        en
        src
      }
      hA {
        en
      }
      pA {
        en
      }
    }
  }
  locationdata: allDataYaml(filter: {yamlId: {eq: "location-data"}}) {
    nodes {
      locations {
        en
        hasRegions
        isDisabled
        isOpen
        list {
          list {
            list {
              en
            }
            en
          }
          en
        }
      }
    }
  }
  howtoenroll: allDataYaml(filter: {yamlId: {eq: "howtoenroll"}}) {
    nodes {
      anchor
      hA {
        en
      }
      imgA {
        en
        src
      }
      hB {
        en
      }   
      pA {
        en
      }
      imgB {
        en
        src
      }
      hC {
        en
      }
      pB {
        en
      }
    }
  }
  contact: allDataYaml(filter: {yamlId: {eq: "contact"}}) {
    nodes {
      hA {
        en
      }
      pA {
        en
      }
      listA {
        en
      }
    }
  }
  footer: allDataYaml(filter: {yamlId: {eq: "footer"}}) {
    nodes {
      imgA {
        en
        src
      }
      pA {
        en
      }
      pB {
        en
      }
      linkA {
        en
        url
      }
      linkB {
        en
        url
      }
    }
  }
}
`

