import React from 'react'

export default function Contact({ lang, contact}) {
  return (
    <div id='contact'>
        <div className='section-divider-white'></div>
        <div className='inner'>
            <div className='col'>
                <h3>{contact.hA[lang]}</h3>
                <p dangerouslySetInnerHTML={{__html: contact.pA[lang]}}/>
                <ul>
                    {
                      contact.listA.map((contactItem, index)=>(
                        <li key={index}>{contactItem[lang]}</li>
                      ))
                    }
                </ul>                
            </div>
        </div>
    </div>
  )
}
