import React from 'react'

export default function WhyTakePart({lang, whytakepart}) {

  return (
    <div id={whytakepart.anchor} className='m-w-t-p nav-section'>
        <div className='section-divider-white'></div>
        <div className='inner'>
            <div className='title-icon'>
              <img src={whytakepart.imgA.src} className='icon' alt={whytakepart.imgA[lang]}/>
              <h2>{whytakepart.hA[lang]}</h2>
            </div>
            <p>{whytakepart.pA[lang]}</p>
            <ul>
              {
                whytakepart.listA.map((item, index)=>(
                  <li key={index}><img 
                  src={item.img.src}
                  className='checkmark'
                  alt={item.img[lang]}
                  key={index + 'img'}/>{item[lang]}</li>
                ))
              }
            </ul>
        </div>
    </div>
  )
}
